import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class LocalizacionService {
  paginate (params) {
    return http.get(`${baseUrl}/localizacion/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  crearLoc (data) {
    return http.post(`${baseUrl}/localizacion`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarLoc (data) {
    return http.put(`${baseUrl}/localizacion/${data.id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  inactivarLoc (id) {
    return http.delete(`${baseUrl}/localizacion/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  getLocalizacion (id) {
    return http.get(`${baseUrl}/localizacion/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  listarLocalizaciones (name) {
    return http.get(`${baseUrl}/localizacion/search/${name}`, {
      headers: {
        loading: true
      }
    })
  }

  listarDepartamentos (name) {
    return http.get(`${baseUrl}/localizacion/listar-departamentos/${name}`, {
      headers: {
        loading: true
      }
    })
  }

  listarMunicipios (name, params) {
    return http.get(`${baseUrl}/localizacion/listar-municipios/${name}`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
