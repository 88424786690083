import { defineStore } from 'pinia'
import LocalizacionService from '../services/localizacion.service'
const _LocalizacionService = new LocalizacionService()

export const useLocalizacionesStore = defineStore('localizacionesStore', {
  state: () => ({
    localizaciones: []
  }),
  getters: {
    _localizaciones: state => state.localizaciones
  },
  actions: {
    obtenerLocalizaciones (ubicacion) {
      _LocalizacionService.listarLocalizaciones(ubicacion).then(({ data }) => {
        this.localizaciones = data
      })
    }
  }
})
